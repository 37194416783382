.modal-slider {
  .slick-slider {
    width: 100%;
    padding: 0 60px;
    // height: 100%;

    @media (max-width: $screen-md) {
      padding: 0;
    }
  }

  .slick-track {
    height: 100%;
  }

  .slick-prev,
  .slick-next {
    width: 48px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: $screen-md) {
      display: none !important;
    }

    &:before {
      content: '';
      display: none;
    }
  }

  .slick-prev {
    left: 0;
    background-image: url(../assets/images/ico-slick-left.svg);
  }
  .slick-next {
    right: 0;
    background-image: url(../assets/images/ico-slick-right.svg);
  }

  .slick-list {
    // height: 100%;
  }

  .slick-slide {
    // aspect-ratio: 1/1;
    height: 100%;
  }

  .slick-dots {
    left: 0;
    bottom: -60px;

    .slick-active {
      .slick-dot {
        background: #828282;
      }
    }
  }

  .modal-slider-image {
    // padding: 0 4.5rem;
    // height: 100%;
    /* display: flex !important;
    justify-content: center !important;
    align-items: center !important; */

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .modal-slider-item-video {
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 16/9;
    transform: translateY(66%);
  }

  .modal-slider-video {
    width: 100%;
    height: 100%;

    .video-container {
      aspect-ratio: 16/9;

      iframe {
        position: relative;
      }
    }
  }

  .slick-initialized .slick-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative;
  }
}

// 커스텀 슬라이드
.slider-custom {
  display: flex;
  align-items: end;
  // padding-bottom: 60px;

  .slick-list {
    width: 100%;
  }

  .slick-slide {
    // width: 100% !important;
  }

  .slick-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
  }

  .slick-prev,
  .slick-next {
    width: 33%;
    height: 90%;
    top: 10%;
    transform: none;

    @media (max-width: $screen-md) {
      display: none !important;
    }

    @media (hover: hover) {
      &:hover {
        cursor: url('../assets/images/ico-cursor-history.svg'), auto;
      }
    }

    &:before {
      content: '';
      display: none;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}
