#guide {
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: var(--app-height);

  &.hidden {
    display: none;
  }

  .guide-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    // background: rgba(0, 0, 0, 0.4);
    z-index: 5005;
  }

  .guide-contents {
    width: 100%;
    max-width: 900px;
    z-index: 5010;
    padding: 1.25rem;
    background: rgba(241, 241, 241, 0.9);

    @media (max-width: $screen-md) {
      width: calc(100% - 2rem);
    }
  }

  .guide-icon {
    width: 4rem;
    height: 4rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 4rem;
    margin: 0 auto;

    &.move {
      background-image: url('../assets/images/ico-guide-move.png');
    }
    &.point {
      background-image: url('../assets/images/ico-guide-contents.png');
    }
    &.mouse {
      background-image: url('../assets/images/ico-guide-mouse.png');
    }
    &.keyboard {
      background-image: url('../assets/images/ico-guide-keyboard.png');
    }
    &.pinch {
      background-image: url('../assets/images/ico-guide-pinch.png');
    }
    &.menu {
      background-image: url('../assets/images/ico-guide-menu.png');
    }
  }

  p {
    margin-top: 1.5rem;

    @media (max-width: $screen-md) {
      margin-top: 0.5rem;
    }
  }
}
