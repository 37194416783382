.search-container {
  max-width: 1920px;
  margin: 0 auto;
}

.search-item {
  transition: all 100ms ease-in-out;
  overflow: hidden;
  background-color: $color-white;
  // position: relative;
  width: 340px;

  @media (max-width: $screen-sm) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.15);

    @media (hover: hover) {
      // transform: scale(1.1);

      .search-item-container {
        &:after {
          content: ' ';
          position: absolute;
          top: 0.25rem;
          right: 0.75rem;
          width: 3rem;
          height: 2rem;
          background-image: url('../assets/images/ico-arrow-line-right.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .serach-image {
    aspect-ratio: 328 / 150;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .search-item-container {
    position: relative;
    height: 100%;
  }

  .search-item-thumbnail {
    width: 100%;
    transition: all 300ms ease-in-out;
    background-color: $color-white;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .search-item-info {
    position: absolute;
    bottom: 0;
    background-color: $color-white;
    width: 100%;
    transition: all 300ms ease-in-out;
    // box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    @extend .bg-shadow;

    .search-item-tags {
      transition: all 300ms ease-in-out;
    }
  }

  .search-item-date {
    padding: 2px 0.75rem;
    height: 5rem;
    @extend .font-ginto-nord;
    @include font-styles(4.5rem, 100%, 400, $color-black);
  }

  // 유형별
  // 제품
  &.search-item-products {
    aspect-ratio: 340 / 280;

    .search-item-thumbnail {
      aspect-ratio: 340 / 208;

      img {
        // aspect-ratio: 340 / 208;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        padding: 2rem;
      }
    }

    @media (hover: hover) {
      &:hover {
        .search-item-info {
          transform: translateY(2.5rem);
        }
      }
    }
  }

  // 연혁
  &.search-item-histories {
    aspect-ratio: 340 / 280;

    .search-item-thumbnail {
      @extend .bg-shadow;
      position: absolute;
      top: 3rem;

      img {
        aspect-ratio: 340 / 152;
      }
    }

    @media (hover: hover) {
      &:hover {
        .search-item-thumbnail {
          top: 5rem;
        }
      }
    }

    // 썸네일 없을 경우
    &.not-has-thumbnail {
      aspect-ratio: 340 / 220;

      .search-item-info {
        .search-item-tags {
          padding-top: 2rem;
        }
      }

      @media (hover: hover) {
        &:hover {
          .search-item-info {
            .search-item-tags {
              padding-top: 0;
            }
          }
        }
      }

      &.search-item-histories {
        .search-item-info {
          // height: 172px;
          aspect-ratio: 340/172;

          .search-item-tags {
            // padding-top: 56px;
            margin-top: 0;
            padding-top: 0;
            position: absolute;
            bottom: 0.5rem;
            left: 0.75rem;
          }
        }

        @media (hover: hover) {
          &:hover {
            .search-item-info {
              height: 140px;
            }
          }
        }
      }
    }
  }

  // 에피소드
  &.search-item-episodes {
    aspect-ratio: 340 / 300;

    .search-item-thumbnail {
      @extend .bg-shadow;
      position: absolute;
      top: 3rem;

      img {
        aspect-ratio: 340 / 152;
      }
    }

    @media (hover: hover) {
      &:hover {
        .search-item-thumbnail {
          top: 138px;
        }
      }
    }

    // 썸네일 없을 경우
    &.not-has-thumbnail {
      .search-item-info {
        .search-item-tags {
          padding-top: 6.25rem;
        }
      }

      @media (hover: hover) {
        &:hover {
          .search-item-info {
            .search-item-tags {
              padding-top: 0.5rem;
            }
          }
        }
      }
    }
  }

  // 기증
  &.search-item-donate {
    aspect-ratio: 340 / 280;
  }

  // 미디어
  &.search-item-resources {
    aspect-ratio: 340 / 280;

    .search-item-thumbnail {
      @extend .bg-shadow;
      position: absolute;
      top: 3rem;

      img {
        aspect-ratio: 340 / 232;
      }
    }

    @media (hover: hover) {
      &:hover {
        .search-item-thumbnail {
          top: 7.5rem;
        }
      }
    }
  }
}

.ico-quotes {
  background-image: url('../assets/images/ico-double-quote.svg');
  background-position: top center;
  background-size: 1.57rem 1.3125rem;
  background-repeat: no-repeat;
}

$search-filter-width: 920px;

.search-filters-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2020;
}

.search-filters {
  width: $search-filter-width;
  /* position: sticky;
  bottom: 0;
  left: 0; */
  // smargin-left: $search-filter-width * -0.5;
  // transform: translateX(-50%);
  height: 72px;
  background: rgba(36, 36, 36, 0.95);
  border-radius: 1.5rem 1.5rem 0 0;
  color: $color-white;
  z-index: 1;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);

  @media (max-width: $screen-lg) {
    width: calc(100vw - 9.375rem);
    height: 4.5rem;
    left: 0;
    margin-left: 0;
    transition: 300ms all ease-in-out;
  }

  &.show-mobile {
    @media (max-width: $screen-lg) {
      width: calc(100vw - 1.5rem);
      left: 0;
    }
  }

  .search-filters-nav {
    background-color: #565656;
    background-image: url('../assets/images/logo-voyage.png');
    background-repeat: no-repeat;
    background-position: left 0.75rem bottom 0.125rem;
    background-size: auto 3.25rem;
    border-radius: 1.5rem 1.5rem 0 0;

    @media (max-width: $screen-lg) {
      background-size: auto 2rem;
      background-position: left 0.75rem bottom 0.875rem;
    }
  }

  .search-filters-keyword {
    width: 262px;
    height: 100%;
    background: rgba(86, 86, 86, 0.9);
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem 1.5rem 0 0;
    padding-left: 120px;
    background-image: url('../assets/images/logo-voyage.png');
    background-position: left 1rem bottom 0.125rem;
    background-repeat: no-repeat;
    background-size: auto 52px;

    @media (max-width: $screen-lg) {
      padding-left: 0;
      background-color: #000;
      position: absolute;
      width: 100%;
      height: 12.5rem;
      bottom: -12.5rem;
      padding: 1rem 1.25rem;
      background-image: none;
      transition: 300ms all ease-in-out;

      form {
        // display: none;
      }
    }

    input {
      display: block;
      width: 110px;
      min-width: 0;
      max-width: 100%;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      border: none;
      padding-left: 1.5rem;
      background: url('../assets/images/ico-search.svg');
      background-position: left center;
      background-repeat: no-repeat;
      background-color: transparent;

      &::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }

      @media (max-width: $screen-lg) {
        width: 100%;
        height: 3rem;
        background-color: #565656;
        box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 1.25rem;
        padding-left: 2.75rem;
        background-position: left 1.25rem center;
      }
    }

    &.show-mobile {
      bottom: 0;
    }
  }

  .search-filters-tags {
    @media (max-width: $screen-lg) {
      position: fixed;
      left: 0.75rem;
      bottom: -16.875rem;
      width: calc(100vw - 1.5rem);
      height: 16.875rem;
      background-color: #000;
      z-index: 0;
      transition: 300ms all ease-in-out;
      border-radius: 1.5rem 1.5rem 0 0;
    }
  }

  select {
    width: auto;
    height: 2.375rem;
    color: $color-black;
    border-radius: 1rem;
    padding: 0 1.25rem;
  }

  &.expand-keyword {
    .search-filters-keyword {
      width: 780px;

      @media (max-width: $screen-lg) {
        width: 100%;
      }

      input {
        width: 500px;

        @media (max-width: $screen-lg) {
          width: 100%;
        }
      }
    }

    .search-filters-tags {
      display: none;
    }
  }

  &.show-mobile {
    .search-filters-tags {
      &.show-mobile {
        @media (max-width: $screen-lg) {
          bottom: 0;
        }
      }
    }
  }

  .search-filters-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    z-index: -1;
  }
}

// 필터 선택창
.search-filters-select {
  position: absolute;
  bottom: 100%;
  width: $search-filter-width;
  transform: translateY(-4px);
  padding: 1rem 7.5rem 1rem 1.25rem;
  background-color: #000;
  border-radius: 1.5rem;

  @media (max-width: $screen-lg) {
    width: 100%;
    bottom: 16.875rem;
    padding: 1rem 1.25rem;
  }

  .search-filters-select-label {
    position: relative;
    @extend .color-gray;

    &:after {
      content: ' ';
      position: absolute;
      right: 6px;
      top: 0;
      width: 1px;
      height: 100%;
      @extend .bg-gray;

      @media (max-width: $screen-lg) {
        width: 100%;
        height: 1px;
        right: 0;
        top: calc(100% + 0.5rem);
      }
    }
  }
}

// 검색 찾을수 없음
.search-not-found {
  background-image: url('../assets/images/ico-notfound.svg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 7.5rem 4.5rem;
}

.search-item-fake-image {
  aspect-ratio: 320 / 187;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
  background-color: $color-light-gray;
  background-image: url('../assets/images/ico-notfound.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7.5rem 4.5rem;
}

// 시대 검색
.search-era {
  position: relative;
  z-index: 1000;
  border-radius: 1.25rem;

  &.shadow {
    background-color: $color-light-gray;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
}

.search-era-container {
  @media (max-width: $screen-md) {
    width: calc(100vw - 6.25rem);
    left: 0.75rem;
    overflow-x: auto;
    border-radius: 1.25rem;
  }
}

// 검색 모달
.search-modal {
  position: fixed;
  top: 2.5rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 2.5rem);
  background-color: #f1f1f1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 4);
  transition: all 300ms ease-in-out;
  transform: translateY(100%);
  overflow-y: auto;
  z-index: 2010;

  &.open {
    transform: translateY(0);
  }

  &.expand {
    top: 0;
    height: 100vh;
    height: var(--app-height);
  }
}

.search-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.5rem;
  z-index: 2000;
}
