:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  // overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

body {
  color: $color-black;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  @extend .bg-light-gray;
}

#main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow-y: auto;
  // overflow: hidden;
  // min-height: -webkit-fill-available;
  // height: calc(100vh - env(safe-area-inset-bottom));
}

// Mobile RWD
// iPhone X
@media only screen and (max-width: 414px) {
  html {
    font-size: 16px;
  }
}

// iPhone 6,7,8,SE2
@media only screen and (max-width: 375px) {
  html {
    font-size: 15px;
  }
}

// Galaxy
@media only screen and (max-width: 360px) {
  html {
    font-size: 14px;
  }
}

// iPhone SE
@media only screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}

* {
  word-break: keep-all;
}

// 스크롤바 숨기기
::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}

// Font
.font-sm3spnaru {
  font-family: 'SM3SPNaru', 'Noto Sans KR', 'Apple Gothic', 'HY Gulim',
    'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  letter-spacing: -4%;
}
.font-ginto-nord {
  font-family: 'GintoNord', 'Noto Sans KR', 'Apple Gothic', 'HY Gulim',
    'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  letter-spacing: -4%;
}
.font-eulyoo1945 {
  font-family: 'Eulyoo1945', serif;
}

// Shadow
.bg-shadow {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

.bg-black-alpha {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-shadow-inner {
  background: #565656;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.bg-gray-gradient {
  background-color: #b5b5b5;
}

// Para
.leading-md {
  line-height: 140%;
}
.leading-base {
  line-height: 150%;
}
.leading-lg {
  line-height: 170%;
}

// Loading
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  background-image: url('../assets/images/logo-voyage-black.svg');
  background-position: center;
  background-repeat: no-repeat;
}
/* .loading {
  width: 25%;
  height: 50vh;
  margin: 0 auto;
  position: relative;
  top: 25vh;

  @media (max-width: $screen-md) {
    width: 100%;
    height: 100vh;
    top: 0;
  }

  @media (max-width: $screen-sm) {
    width: 100%;
    height: 40vh;
    margin-top: 30vh;
    top: 0;
  }
} */

// Video
.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $screen-md) {
      aspect-ratio: 366/210;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
