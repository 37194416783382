.btn {
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  &.btn-icon {
    text-indent: -9999rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.btn-enter {
    @include font-styles(18px, 18px, 700, #fff);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 40px;
    letter-spacing: -0.02em;
    padding: 0.9375rem 5.25rem 0.9375rem 2.5rem;
    border: 1px solid #ffffff;
    background: rgba(255, 255, 255, 0.1);
    background-image: url('../assets/images/ico-arrow-right-white.svg');
    background-position: right 2.5rem center;
    background-repeat: no-repeat;
  }

  &.btn-clear {
    @include font-styles(0.875rem, 1.75rem, 400);
    padding-left: 1rem;
    background-image: url('../assets/images/ico-refresh-white.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 0.75rem;
    border-bottom: 2px solid;
    @extend .border-primary;
  }

  &.btn-close-block {
    @extend .btn-icon;
    width: 3.75rem;
    height: 3rem;
    background-color: $color-white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 1.25rem;
    background-image: url('../assets/images/ico-close-black.svg');
  }

  &.btn-close-white {
    @extend .btn-icon;
    background-image: url('../assets/images/ico-close-white.svg');
  }

  &.btn-pill {
    font-weight: 500;
    height: 3rem;
    line-height: 3rem;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 1.25rem;
    padding: 0 1rem;

    &.px-5 {
      padding: 0 1.25rem;
    }

    &.btn-pill-inline {
      width: 3.75rem;
    }

    &.btn-pill-inline-alt {
      width: 3rem;
      height: 2.5rem;
      border-radius: 30px;
    }

    &.btn-pill-sm {
      height: 2.5rem;
      line-height: 2.5rem;
      box-shadow: none;
    }

    &.active {
      background-color: $color-light-gray;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    }

    &.search {
      padding-right: 2.5rem;
      background-image: url('../assets/images/ico-search-black.svg');
      background-repeat: no-repeat;
      background-position: right 1rem center;
    }

    &.floor {
      padding-right: 2.75rem;
      background-image: url('../assets/images/ico-floor.svg');
      background-repeat: no-repeat;
      background-position: right 1rem center;

      @media (max-width: $screen-md) {
        background-image: url('../assets/images/ico-floor-mobile.svg');
        background-size: 2rem;
      }

      &.mobile-close {
        @media (max-width: $screen-md) {
          background-image: url('../assets/images/ico-close-line.svg');
          background-size: 2rem;
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    &.close {
      padding-right: 2.75rem;
      background-image: url('../assets/images/ico-close-black.svg');
      background-repeat: no-repeat;
      background-position: right 1rem center;
    }

    &.plus {
      background-image: url('../assets/images/ico-plus.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.minus {
      background-image: url('../assets/images/ico-minus.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.arrow-left {
      background-image: url('../assets/images/ico-arrow-left.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.arrow-right {
      background-image: url('../assets/images/ico-arrow-right.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &.bg-center {
      background-position: center;
    }

    &.md-icon-only {
      @media (max-width: $screen-md) {
        width: 3.75rem;
        padding-left: 0;
        text-indent: -9999px;
        background-position: center;
      }
    }
  }

  &.btn-line {
    font-weight: 600;
    height: 3rem;
    line-height: 3rem;
    background-color: transparent;

    &.btn-line-sm {
      height: 1.5rem;
      line-height: 1.5rem;
    }

    &.btn-line-inline {
      width: 3rem;
    }
    &.btn-line-inline-long {
      width: 3.75rem;
    }

    &.active {
      background-color: $color-light-gray;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    }

    &.search {
      padding-right: 2.5rem;
      background-image: url('../assets/images/ico-search-black.svg');
      background-repeat: no-repeat;
      background-position: right 1rem center;
    }

    &.floor {
      padding-right: 2.75rem;
      background-image: url('../assets/images/ico-floor.svg');
      background-repeat: no-repeat;
      background-position: right 1rem center;
    }

    &.close {
      background-size: contain;
      background-image: url('../assets/images/ico-close-line.svg');
      background-repeat: no-repeat;
      background-position: center;

      &.white {
        background-image: url('../assets/images/ico-close-line-white.svg');
      }
    }

    &.plus {
      background-image: url('../assets/images/ico-plus.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.minus {
      background-image: url('../assets/images/ico-minus.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &.arrow-left {
      background-image: url('../assets/images/ico-arrow-line-left.svg');
      background-repeat: no-repeat;
      background-position: center;

      &.white {
        background-image: url('../assets/images/ico-arrow-line-left-white.svg');
      }
    }
    &.arrow-right {
      background-image: url('../assets/images/ico-arrow-line-right.svg');
      background-repeat: no-repeat;
      background-position: center;

      &.white {
        background-image: url('../assets/images/ico-arrow-line-right-white.svg');
      }
    }

    &.bg-center {
      background-position: center;
    }

    &.md-icon-only {
      @media (max-width: $screen-md) {
        width: 3.75rem;
        padding-left: 0;
        text-indent: -9999px;
        background-position: center;
      }
    }
  }

  &.btn-to-top {
    padding-top: 3.5rem;
    @include font-styles(0.875rem, 140%, 400);
    background-image: url('../assets/images/ico-arrow-line-top.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 3rem;
  }

  &.btn-arrow {
    @extend .btn-icon;
    height: 3rem;
    background-color: $color-white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 1.25rem;

    &.btn-arrow-inline {
      width: 3.75rem;
    }

    &.left {
      background-image: url('../assets/images/ico-arrow-black-left.svg');
    }
    &.right {
      background-image: url('../assets/images/ico-arrow-black-right.svg');
    }
  }

  &.btn-select {
    display: block;
    width: 100px;
    padding: 0.5rem 1.5rem 0.5rem 0.75rem;
    background-color: $color-white;
    border-radius: 1rem;
    background-image: url('../assets/images/ico-chev-down.svg');
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 0.75rem;
    text-align: left;
    @include font-styles(0.875rem, 1.25rem, 400, $color-black);

    &.w-auto {
      display: inline-block;
      padding-right: 2.375rem;
      width: auto;
    }

    &.open {
      color: $color-white;
      background-color: #565656;
      background-image: url('../assets/images/ico-chev-up-white.svg');
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    }

    &.close {
      color: $color-white;
      background-color: #565656;
      background-image: url('../assets/images/ico-close-white.svg');
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    }

    &.clear {
      color: $color-white;
      background-image: none;
      background-color: transparent;

      @media (hover: hover) {
        &:hover {
          color: $color-white;
          background-color: #565656;
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  &.btn-top {
    @extend .btn-icon;
    width: 3.75rem;
    height: 4.5rem;
    border-radius: 1.5rem 1.5rem 0 0;
    background-color: #000;
    position: fixed;
    left: 1rem;
    bottom: 0;
    background-image: url('../assets/images/ico-arrow-top.svg');
    background-size: 1rem 1rem;
    display: none;

    @media (max-width: $screen-lg) {
      width: 3.1875rem;
      height: 4.5rem;
      left: 0.75rem;
    }
  }

  &.btn-ham {
    @extend .btn-icon;
    background-image: url('../assets/images/ico-ham.svg');
    background-size: 1rem;
  }

  &.btn-refresh {
    @extend .btn-icon;
    background-image: url('../assets/images/ico-refresh-white.svg');
    background-size: 1rem;
  }

  &.btn-sound {
    @extend .btn-icon;
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-size: 2rem;

    &.off {
      background-image: url('../assets/images/ico-sound-on.png');
    }
    &.on {
      background-image: url('../assets/images/ico-sound-off.png');
    }
  }

  &.btn-guide {
    @extend .btn-icon;
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-size: 2rem;
    background-image: url('../assets/images/ico-guide.png');
  }

  &.btn-mouse-wheel {
    @extend .btn-icon;
    width: 208px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-image: url('../assets/images/img-mouse-wheel.png');
  }
}
