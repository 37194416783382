// Masonnry
.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  // margin-left: -2rem; /* gutter size offset */
  width: auto;
  justify-content: center;

  @media (max-width: $screen-lg) {
    margin-left: -1.25rem;
  }
}
.masonry-grid-column {
  width: 340px !important;
  // padding-left: 2rem; /* gutter size */
  background-clip: padding-box;

  @media (max-width: $screen-lg) {
    padding-left: 1.25rem;
  }
}

/* Style your items */
.masonry-grid-column > div {
  margin-bottom: 2rem;

  @media (max-width: $screen-lg) {
    margin-bottom: 1.25rem;
  }
}

.masonry-grid-column.masonry-grid-column-sm {
  @media only screen and (max-width: ($screen-lg - 1)) {
    // padding-left: 0.75rem;
  }
}

.masonry-grid-column.masonry-grid-column-sm > div {
  margin-bottom: 2rem;

  @media (max-width: $screen-lg) {
    margin-bottom: 1.25rem;
  }
}
