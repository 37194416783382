$color-black: #262626;
$color-white: #ffffff;
$color-gray: #9f9ea3;
$color-gray-alt: #8c8b8f;
$color-light-gray: #e3e3e3;
$color-extra-light-gray: #f1f1f1;
$color-dark-gray: #323232;
$color-light-gray-alt: #d1d4da;

$colorList: (
  'black': $color-black,
  'white': $color-white,
  'primary': #0038ff,
  'blue': #4791ff,
  'light-blue': #c2dce9,
  'green': #7cef99,
  'light-green': #d5eac0,
  'gray': $color-gray,
  'gray-alt': $color-gray-alt,
  'light-gray': $color-light-gray,
  'extra-light-gray': $color-extra-light-gray,
  'dark-gray': $color-dark-gray,
  'light-yellow': #fef8aa,
  'light-gray-alt': $color-light-gray-alt,
);

$font-family-sans-serif: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'Apple Gothic',
  'HY Gulim', 'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;

$screen-sm: 422px - 1px;
$screen-md: 776px - 1px;
$screen-lg: 1148px - 1px;
$screen-xl: 1520px - 1px;

// Mixin
@mixin font-styles($size, $lineHeight, $weight, $color: null) {
  @if $size {
    font-size: $size;
  }
  @if $lineHeight {
    line-height: $lineHeight;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $color {
    color: $color;
  }
}

@each $colorName, $hex in $colorList {
  .color-#{$colorName} {
    color: $hex;
  }
  .bg-#{$colorName} {
    background-color: $hex;
  }
  .border-#{$colorName} {
    border-color: $hex;
  }
}
