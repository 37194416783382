@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/JJGothic/JJGothic-Thin.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Thin.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/JJGothic/JJGothic-Light.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Light.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/JJGothic/JJGothic-Regular.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Regular.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/JJGothic/JJGothic-Medium.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Medium.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/JJGothic/JJGothic-Bold.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Bold.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/JJGothic/JJGothic-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'JJGothic';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/JJGothic/JJGothic-Heavy.woff2') format('woff2'),
    url('../assets/fonts/JJGothic/JJGothic-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'SM3SPNaru';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/SM3SPNaru/SM3SPNaru-03-FD1.woff2') format('woff2'),
    url('../assets/fonts/SM3SPNaru/SM3SPNaru-03-FD1.woff') format('woff');
}

@font-face {
  font-family: 'GintoNord';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/GintoNord/GintoNord-Regular.woff2') format('woff2'),
    url('../assets/fonts/GintoNord/GintoNord-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GintoNord';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/GintoNord/GintoNord-Medium.woff2') format('woff2'),
    url('../assets/fonts/GintoNord/GintoNord-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Eulyoo1945';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Eulyoo1945/Eulyoo1945Regular.woff2') format('woff2'),
    url('../assets/fonts/Eulyoo1945/Eulyoo1945Regular.woff') format('woff');
}
