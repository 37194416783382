.vr-container {
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  #vr-pano {
    width: 100%;
    height: 100vh;
    height: var(--app-height);

    * {
      box-sizing: content-box;
    }
  }
}

.vr-menu {
  .vr-menu-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: var(--app-height);
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 10;
    display: none;
  }
  .vr-menu-container {
    position: relative;
    z-index: 20;
  }
  .btn-pill {
    border-radius: 30px;
  }
  .vr-menu-list {
    opacity: 0;
    visibility: hidden;
    transition: all 100ms ease-in-out;

    @media (max-width: $screen-md) {
      width: calc(100vw - 1.5rem);
    }

    ul {
      li {
        width: 245px;
        opacity: 0;
        visibility: hidden;

        @media (max-width: $screen-md) {
          width: 100%;
        }

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 50ms;
          }
        }
      }
    }
  }

  .vr-menu-item {
    // transition: all 100ms ease-in-out;
    display: flex;
    align-items: center;

    &.vr-menu-item-mobile {
      display: none;

      @media (max-width: $screen-md) {
        display: flex;
      }
    }

    &.btn.btn-pill {
      padding: 0 1rem 0 1rem;
      box-shadow: none;

      @media (max-width: $screen-md) {
        height: 3.5rem;
        line-height: 3.5rem;
        padding: 0 1.5rem 0 1.5rem;
      }

      &.active {
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.4);
      }

      &.floor {
        @media (max-width: $screen-md) {
          width: 5rem;
          height: 3rem;
          min-width: 0 !important;

          .name,
          .year,
          .arrow {
            display: none;
          }
        }
      }
    }

    .name {
      width: 82px;
      @include font-styles(18px, 2rem, 500, $color-black);
      margin-right: 0.25rem;
      letter-spacing: -0.04em;

      @media (max-width: $screen-md) {
        width: 38%;
        margin-right: 0.5rem;
        @include font-styles(1.625rem, 1.875rem, 500, $color-black);
        letter-spacing: -0.02em;

        &.long {
          width: max-content;
          margin-right: 0;
        }
      }
    }
    .year {
      width: 52px;
      font-family: 'GintoNord', 'Noto Sans KR', 'Apple Gothic', 'HY Gulim',
        'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica',
        'Arial', sans-serif;
      letter-spacing: -4%;
      @include font-styles(20px, 100%, 400, $color-black);
      margin-left: 0 0.25rem;

      @media (max-width: $screen-md) {
        width: 25.4%;
        @include font-styles(1.75rem, 100%, 400, $color-black);
      }
    }
    .arrow {
      display: block;
      width: 30px;
      height: 100%;
      background-image: url('../assets/images/ico-arrow-menu.svg');
      background-position: center;
      background-repeat: no-repeat;
      // margin: 0 0.125rem;

      @media (max-width: $screen-md) {
        width: 2.875rem;
        margin: 0 0.25rem;
        background-size: contain;
        background-image: url('../assets/images/ico-arrow-menu-mobile.svg');
      }

      &.arrow-long {
        width: 75px;
        background-image: url('../assets/images/ico-arrow-menu-long.svg');

        @media (max-width: $screen-md) {
          width: 7.625rem;
          background-image: url('../assets/images/ico-arrow-menu-long-mobile.svg');

          &.extra {
            width: 10.375rem;
            background-image: url('../assets/images/ico-arrow-menu-extra-long-mobile.svg');
          }
        }
      }
    }
  }

  &.show {
    .vr-menu-bg {
      display: block;
    }
    .vr-menu-list {
      opacity: 1;
      visibility: visible;

      ul {
        li {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.vr-reload {
  // background-color: #090b18;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  transition: all 700ms ease-in-out;
  // transform: translateY(-100%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 6000;
  opacity: 0;
  visibility: hidden;

  .vr-reload-logo {
    width: 120px;
    height: 63px;
    background-image: url('../assets/images/logo-voyage-black.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 1000ms ease-in-out;
    z-index: 6020;
    animation: blinker 3s linear infinite;
  }

  &:after,
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: $color-white;
    transition: all 1000ms ease-in-out;
    z-index: 6010;
  }

  &:after {
    transform: translateY(-50vh);
  }
  &:before {
    transform: translateY(100vh);
  }

  &.open {
    opacity: 1;
    visibility: visible;

    .vr-reload-logo {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      transform: translateY(0);
    }
    &:before {
      transform: translateY(50vh);
    }
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  /* .vr-reload-contents {
    color: $color-white;

    .vr-reload-contents-para {
      overflow: hidden;
      p {
        transition: all 500ms ease-in-out 1000ms;
        transform: translateY(100%);
      }
    }
  }

  &.open {
    transform: translateY(0);

    .vr-reload-contents {
      .vr-reload-contents-para {
        p {
          transform: translateY(0);
        }
      }
    }
  }

  &.close {
    transform: translateY(100%);

    .vr-reload-contents {
      .vr-reload-contents-para {
        p {
          transform: translateY(100%);
        }
      }
    }
  }

  &.hide {
    transition: none;
    opacity: 0;
    transform: translateY(-100%);

    .vr-reload-contents {
      .vr-reload-contents-para {
        p {
          transform: translateY(100%);
        }
      }
    }
  } */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
