$max-modal-width: 440px;
$max-modal-lg-width: 1120px;
$modal-header-height: 110px;
$modal-header-era-height: 100px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100vh;
  height: calc(100% - env(safe-area-inset-bottom));
  z-index: 3000;
  transition: all 300ms ease-in-out;

  .modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
  }

  .modal-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: $max-modal-width * 2;
    height: 100%;
    margin-left: $max-modal-width * 2 * -0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3080;
    padding: 80px 0 0 0;
    transition: all 300ms ease-in-out;

    @media (max-width: $screen-md) {
      width: 100%;
      left: 0;
      margin-left: 0;
      padding-top: 1rem;
    }
  }

  .modal-contents {
    z-index: 3000;
    max-width: $max-modal-width;
    width: 100%;
    height: 100%;
    z-index: 3100;
    position: relative;
    background-color: $color-white;
    border-radius: 1.25rem 1.25rem 0 0;
    overflow: hidden;
    background-color: #f1f1f1;

    &.overflow-y-auto {
      overflow-y: auto;
    }

    .modal-header {
      position: absolute;
      top: 0;
      width: 100%;
      height: $modal-header-height;
      overflow: hidden;

      /* &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.75rem;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      } */

      .modal-contents-header-title {
        width: 100%;
        height: 6.5rem;
        background-position: top center;
        background-size: 90% auto;
        background-repeat: no-repeat;
        @include font-styles(7.4rem, 92%, 400, $color-black);

        @media screen and (-moz-windows-theme) {
          line-height: 68% !important;
        }

        font-family: 'GintoNord', 'Noto Sans KR', 'Apple Gothic', 'HY Gulim',
          'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue',
          'Helvetica', 'Arial', sans-serif;
        letter-spacing: 0.03em;
        text-align: center;

        &.충무로 {
          background-image: url('../assets/images/img-building-chungmuro.svg');
        }
        &.신대방 {
          background-image: url('../assets/images/img-building-sdb.svg');
        }
        &.하월곡 {
          background-image: url('../assets/images/img-building-hwg.svg');
        }
        &.서초 {
          background-image: url('../assets/images/img-building-sc.svg');
        }
        &.과천 {
          background-image: url('../assets/images/img-building-gc.svg');
        }
        &.충무로-era {
          top: 160px !important;
          background-image: url('../assets/images/img-building-chungmuro-era.svg');

          @media (max-width: $screen-md) {
            top: 0 !important;
          }
        }
        &.하월곡-era {
          top: 160px !important;
          background-image: url('../assets/images/img-building-hwg-era.svg');

          @media (max-width: $screen-md) {
            top: 0 !important;
          }
        }
      }

      .modal-contents-header-bg {
        width: 100%;
        height: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .modal-body {
      position: absolute;
      top: $modal-header-height;
      width: 100%;
      height: calc(100% - #{$modal-header-height} - 3.75rem);
      // overflow-y: auto;
      // overflow-x: hidden;
      overflow: hidden;
      background-color: #f1f1f1;

      /* width */
      &::-webkit-scrollbar {
        display: block !important;
        width: 4px !important;
        background-color: transparent !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #000000 !important;
        border-radius: 40px !important;
      }

      .modal-body-contents-container {
        overflow-y: auto;
        height: 100%;

        /* width */
        &::-webkit-scrollbar {
          display: block !important;
          width: 4px !important;
          background-color: transparent !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #000000 !important;
          border-radius: 40px !important;
        }
      }

      // margin-top: 76px;
      /* height: calc(100% - 152px);
      overflow-y: auto; */

      .modal-body-fixed {
        position: sticky;
        top: 0;
        z-index: 3005;
        min-height: 65px;
        // height: 105px;
        transition: all 300ms ease;
        visibility: visible;
        opacity: 100;
        height: auto;

        &.narrow {
          min-height: 24px;

          .modal-body-fixed-contents {
            display: none;
          }
        }

        .marquee {
          display: flex;
          flex-wrap: wrap;
          flex-wrap: nowrap;
          width: max-content;
          transform: translateX(0);
          white-space: nowrap;
          will-change: transform;
          animation: marquee 200s linear infinite;

          span {
            @include font-styles(1.75rem, 120%, 300, $color-black);
            padding-right: 1.25rem;
          }
        }

        &:after {
          content: ' ';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0.75rem;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        &.hide {
          visibility: hidden;
          opacity: 0;
          height: 0;
          min-height: 0;
        }
      }

      .modal-body-contents {
        padding: 1rem 2rem 7.5rem 2rem;

        @media (max-width: $screen-md) {
          padding: 1rem 1.2rem 7.5rem 1.2rem;
        }

        p {
          font-size: 1rem;
        }
        // padding-top: 125px;
      }
    }

    .modal-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // background-color: #f1f1f1;

      /* &:after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 2.5rem;
        bottom: 100%;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(241, 241, 241, 0) 0%,
          #f1f1f1 100%
        );
      } */
    }

    &.modal-contents-shadow {
      .modal-body {
        top: 0;
        left: 0;
        height: 100%;
      }

      &:after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 5rem;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(241, 241, 241, 0) 0%,
          #f1f1f1 100%
        );
        z-index: 3110;
      }
    }
  }

  // Size

  // Content type
  &.modal-products {
    .modal-contents {
      .modal-header {
        height: 14rem;
      }

      .modal-body {
        top: 14rem;
        height: calc(100% - 14rem - 3.75rem);
      }
    }
  }

  // Episodes, vArchives
  &.modal-episodes,
  &.modal-vArchive {
    .modal-container {
      width: 100%;
      margin-left: 0;
      left: 0;
      justify-content: end;
    }

    .modal-contents {
      max-width: 1507px;
      border-radius: 1.25rem 0 0 1.25rem;

      @media (max-width: $screen-xl) {
        max-width: 1168px;
      }
      @media (max-width: $screen-lg) {
        max-width: 930px;
      }
      @media (max-width: $screen-md) {
        max-width: 744px;
        border-radius: 1.5rem 1.5rem 0 0;
      }

      .modal-header {
        width: 100px;
        height: 100%;
        left: 0;
        top: 0;

        @media (max-width: $screen-sm) {
          width: 100%;
          height: 7.5rem;
        }

        .modal-header-era {
          display: none;
        }

        .modal-contents-header-title {
          position: absolute;
          top: 120px;
          right: -158px;
          transform: rotate(270deg);
          width: 400px;
          height: 100px;
          background-size: contain;
          font-size: 7rem;

          @media (max-width: $screen-sm) {
            position: relative;
            top: 0;
            right: 0;
            transform: rotate(0);
            width: 100%;
            height: 7.5rem;
          }

          &.충무로 {
            @media (min-width: $screen-md) {
              top: 134px !important;
              background-size: 330px auto !important;
            }

            background-image: url('../assets/images/img-building-chungmuro.svg');
          }
          &.신대방 {
            @media (min-width: $screen-md) {
              top: 134px !important;
              background-size: 330px auto !important;
            }

            background-image: url('../assets/images/img-building-sdb.svg');
          }
          &.하월곡 {
            @media (min-width: $screen-md) {
              top: 134px !important;
              background-size: 330px auto !important;
            }

            background-image: url('../assets/images/img-building-hwg.svg');
          }
          &.서초 {
            @media (min-width: $screen-md) {
              top: 100px !important;
              background-size: 330px auto !important;
            }

            background-image: url('../assets/images/img-building-sc.svg');
          }
          &.과천 {
            @media (min-width: $screen-md) {
              top: 100px !important;
              background-size: 330px auto !important;
            }

            background-image: url('../assets/images/img-building-gc.svg');
          }
        }

        .modal-contents-header-bg {
          display: none;
        }
      }

      .modal-body {
        top: 0;
        left: 80px;
        width: calc(100% - 80px);
        height: 100%;
        overflow: visible;
        background-color: #d5eac0;

        @media (max-width: $screen-sm) {
          top: 4.75rem;
          left: 0;
          width: 100%;
          height: calc(100% - 4.75rem);
        }

        .modal-body-fixed {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 3.75rem;
          height: 100%;
          // transform: rotate(270deg);
          border-right: 1px solid #000;
          box-shadow: -16px 0px 16px rgba(0, 0, 0, 0.15);
          border-radius: 0;

          @media (max-width: $screen-sm) {
            width: 100%;
            height: 3rem;
            min-height: 0;
            box-shadow: none;
            border-right: none;
          }

          .modal-body-fixed-contents {
            transform: rotate(270deg);
            border-bottom: none;

            @media (max-width: $screen-sm) {
              transform: rotate(0deg);
            }
          }
        }

        .modal-contents-era {
          position: absolute;
          top: 0;
          left: 60px;
          width: 472px;
          height: 100%;
          overflow-y: auto;
          background-image: url('../assets/images/ico-arrow-line-side.svg');
          background-position: left 30px bottom 10px;
          background-repeat: no-repeat;
          background-size: 32px;

          p {
            font-size: 1.125rem;
          }

          @media (max-width: $screen-sm) {
            top: 3rem;
            left: 0;
            width: 100%;
            height: calc(100% - 12.1875rem);
            padding-bottom: 6rem;
            overflow-y: auto;
            background-position: right 1rem top 1.25rem;
            // background-image: url('../assets/images/ico-collapse.svg');
            background-image: url('../assets/images/ico-arrow-line-topbottom.svg');
            background-size: 2rem;
          }
        }

        .modal-body-contents {
          position: absolute;
          right: 0;
          top: 0;
          width: calc(100% - 84px);
          height: 100%;
          overflow: visible;
          background-color: #f1f1f1;
          transition: all 300ms ease-in-out;
          padding: 0;
          display: flex;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

          @media (max-width: $screen-md) {
            display: block;
            overflow-y: auto;
          }

          @media (max-width: $screen-sm) {
            width: 100%;
            top: 3rem;
            height: calc(100% - 3rem);

            /* width */
            &::-webkit-scrollbar {
              display: block !important;
              width: 4px !important;
              background-color: transparent !important;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #000000 !important;
              border-radius: 40px !important;
            }
          }

          .modal-body-contents-inner {
            position: relative;
            width: 50%;
            height: 100%;
            overflow-y: auto;
            border-right: 1px solid #000;
            padding: 1.5rem 2rem 7.5rem 2rem;

            @media (max-width: $screen-md) {
              padding: 1.5rem 1.2rem 7.5rem 1.2rem;
            }

            &:after {
              content: ' ';
              position: sticky;
              display: block;
              width: 100%;
              height: 7.5rem;
              bottom: -7.5rem;
              left: 0;
              background: linear-gradient(
                180deg,
                rgba(241, 241, 241, 0) 0%,
                #f1f1f1 58.32%
              );
            }

            @media (max-width: $screen-md) {
              width: 100%;
              height: auto;
              border-right: none;

              &:after {
                content: '';
                display: none;
              }
            }
          }

          .modal-slider {
            width: 50%;

            @media (max-width: $screen-md) {
              width: 100%;
            }
          }
        }
      }

      .modal-footer {
        width: calc(100% - 184px);
        left: auto;
        right: 0;
        transition: all 1000ms ease-in-out 200ms;
        background-color: transparent;

        &:after {
          content: '';
          display: none;
        }

        @media (max-width: $screen-sm) {
          width: 100%;
          // background-color: #f1f1f1;

          /* &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 2.5rem;
            bottom: 100%;
            left: 0;
            background: linear-gradient(
              180deg,
              rgba(241, 241, 241, 0) 0%,
              #f1f1f1 100%
            );
          } */
        }
      }
    }

    &.has-era {
      .modal-contents {
        .modal-body {
          .modal-body-contents {
            width: calc(100% - 140px);

            @media (max-width: $screen-sm) {
              width: 100%;
              top: 7.5rem;
              height: calc(100% - 7.5rem);
            }
          }
        }

        .modal-footer {
          // width: calc(100% - 140px - 104px);
          width: calc(100% - 140px - 80px);
          left: auto;
          right: 0;

          @media (max-width: $screen-sm) {
            width: 100%;
          }
        }
      }

      &.expand {
        .modal-contents {
          .modal-body {
            .modal-body-contents {
              // width: calc(100% - 472px - 60px);
              transform: translateX(398px);

              @media (max-width: $screen-sm) {
                transform: translateX(0);
                transform: translateY(calc(100% - 12.1875rem));
              }
            }

            .modal-contents-era {
              background-image: none;

              @media (max-width: $screen-sm) {
                background-image: url('../assets/images/ico-collapse.svg');
              }
            }
          }

          .modal-footer {
            width: calc(100% - 472px - 146px);
            left: auto;
            right: 0;

            @media (max-width: $screen-sm) {
              width: 100%;
              // display: none;
            }
          }
        }
      }
    }
  }

  // Resource
  &.modal-resource {
    .modal-container {
      width: $max-modal-lg-width * 2;
      // height: max-content;
      margin-left: $max-modal-lg-width * 2 * -0.5;
      // padding: 130px 0;
      padding: 20px 0;

      @media (max-width: $screen-lg) {
        width: calc(100% - 1.5rem);
        margin-left: 0.75rem;
        // padding: 6.25rem 0;
        padding: 2rem 0;
        left: 0;
      }
    }

    .modal-contents {
      max-width: $max-modal-lg-width;
      max-height: 820px;
      border-radius: 1.25rem;

      @media (max-width: $screen-lg) {
        max-width: 100%;
        // max-height: max-content;
        // height: auto;

        width: 100%;
        aspect-ratio: 366/462;
        height: auto;
      }
    }

    .modal-header {
      padding: 6px 0.75rem;
      height: 48px;
      @include font-styles(28px, 120%, 300);
      border-bottom: 1px solid $color-black;

      @media (max-width: $screen-md) {
        @include font-styles(1.25rem, 120%, 300);
        padding: 0.75rem;
      }
    }

    .modal-body {
      top: 48px;
      height: calc(100% - 48px - 5.5rem);
      padding: 40px 0 0 0;

      @media (max-width: $screen-md) {
        // padding-top: 2.5rem;
        top: calc(3rem + 0.75rem);
        padding-top: 0;
        // height: calc(100% - 3rem - 7.5rem);
        // height: auto;
        height: calc(100% - 3rem - 0.75rem - 7.5rem);
      }

      .modal-body-media {
        height: 100%;

        @media (max-width: $screen-md) {
          width: 100%;
          // height: auto;
          // aspect-ratio: 366/282;
          // height: calc(100% - 3rem - 0.75rem - 7.5rem);
          height: 100%;
        }
      }
    }

    .modal-footer {
      &:after {
        content: '';
        display: none;
      }

      .modal-contents-footer-resource-nav {
        position: relative;
        padding-right: 20px;
      }
    }

    .modal-contents-nav-footer {
      width: 280px;
      position: relative;

      @media (max-width: $screen-md) {
        width: 100%;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: -20px;
        bottom: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(
          270deg,
          #f1f1f1 0%,
          rgba(241, 241, 241, 0) 100%
        );
      }
    }
  }

  // 모달 트랜지션
  &.modal-vArchiveintro,
  &.modal-products {
    .modal-container {
      transform: translateY(100%);
    }

    &.open {
      .modal-container {
        transform: translateY(0);
      }
    }
  }

  &.modal-resource {
    .modal-container {
      transform: translateX(-100%);
    }

    &.open {
      .modal-container {
        transform: translateX(0);
      }
    }
  }

  &.modal-episodes,
  &.modal-vArchive {
    .modal-container {
      transition: all 500ms ease-in-out;
      transform: translateX(100%);

      .modal-body {
        transition: all 800ms ease-in-out;
        transition-delay: 200ms;
        transform: translateX(100%);

        .modal-body-contents {
          transition: all 1000ms ease-in-out;
          transition-delay: 200ms;
          transform: translateX(100%);
        }
      }
    }

    &.open {
      .modal-container {
        transform: translateX(0);
        .modal-body {
          transform: translateX(0);

          .modal-body-contents {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

// 시대
.modal {
  &.modal-rndMedia,
  &.modal-vArchiveintro {
    .modal-contents {
      .modal-header {
        height: 104px;

        @media (max-width: $screen-sm) {
          height: 6rem;
        }

        .modal-contents-header-title {
          height: 5rem;
          margin-top: 0.5rem;

          @media (max-width: $screen-sm) {
          }
        }
      }
      .modal-body {
        top: 104px;
        // height: calc(100% - 104px - 3.75rem);
        height: calc(100% - 104px);

        @media (max-width: $screen-sm) {
          top: 6rem;
          // height: calc(100% - 6rem - 3.75rem);
          height: calc(100% - 6rem);
        }
      }
    }
  }

  &.modal-custom {
    .modal-contents {
      .modal-header {
        @media (max-width: $screen-sm) {
          height: 4.5rem;
        }
        .modal-contents-header-title {
          top: 160px;

          @media (max-width: $screen-sm) {
            top: 0;
            height: 4.25rem;
          }
        }
      }

      .modal-body {
        @media (max-width: $screen-sm) {
          top: 4.5rem;
          height: calc(100% - 4.5rem);
        }
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

// Image modal
.modal-images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  background-color: #565656;
  z-index: 4000;

  button,
  .buttons {
    z-index: 4050;
  }

  #modal-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    height: var(--app-height);
  }

  #modal-image {
    position: relative;
    display: flex;
    align-items: center;
    max-width: none;
    cursor: grab;
  }
}

// Window hack
.win {
  .modal .modal-contents .modal-header .modal-contents-header-title {
    line-height: 68%;
  }
}

// History group modal
.modal-histories-group {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100vh;
  height: calc(100vh - env(safe-area-inset-bottom));
  z-index: 3000;
  /* visibility: hidden;
  opacity: 0;
  top: 100%;

  &.show {
    visibility: visible;
    opacity: 1;
    top: 0;
  } */

  .modal-histories-group-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
  }

  .modal-histories-group-container {
    position: absolute;
    top: 0;
    left: 0;
    // left: calc(50vw - #{$max-modal-width * 0.5});
    width: 100%;
    height: 100vh;
    height: var(--app-height);
    overflow-x: auto;
    // overflow-x: hidden;
    padding: 0 calc(50vw - #{$max-modal-width * 0.5});

    .modal-histories-group-contents {
      display: flex;
      width: max-content;
      height: 100%;
    }

    .modal-histories-item {
      width: $max-modal-width;
      height: 100vh;
      height: var(--app-height);
      overflow: hidden;

      @media (max-width: $screen-sm) {
        width: 100vw;
      }

      .modal {
        position: relative;

        .modal-container {
          width: $max-modal-width;
          padding-top: 196px;
          margin-left: 0;
          left: 0;
          transition: all 500ms ease-in-out;

          @media (max-width: $screen-sm) {
            width: 100vw;
          }

          .modal-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background-color: transparent;

            @media (hover: hover) {
              &:hover {
                cursor: url('../assets/images/ico-cursor-history.svg'), auto;
              }
            }
          }

          .modal-contents {
            // box-shadow: 0px -4px -40px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0 -4px 40px rgba(0, 0, 0, 0.25);

            .modal-header {
              opacity: 0.5;
              background-color: $color-white;
            }

            .modal-body {
              overflow: hidden;

              @media (max-width: $screen-sm) {
                &:after {
                  /* content: ' ';
                  background: linear-gradient(
                    180deg,
                    rgba(241, 241, 241, 0) 0%,
                    #f1f1f1 100%
                  );
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 2.5rem;
                  z-index: 20; */
                }
              }

              img {
                filter: grayscale(100%);
              }

              .modal-body-contents {
                border-bottom: 1px solid #000;
                opacity: 0.5;
                overflow: hidden;
                min-height: 360px;

                &:last-child {
                  border-bottom: none;
                }
              }
            }

            .modal-footer {
              @media (max-width: $screen-sm) {
                &:after {
                  content: ' ';
                  background: linear-gradient(
                    180deg,
                    rgba(241, 241, 241, 0) 0%,
                    #f1f1f1 100%
                  );
                  position: absolute;
                  bottom: 100%;
                  left: 0;
                  width: 100%;
                  height: 2.5rem;
                  z-index: 20;
                }
              }
            }
          }
        }
      }

      &.current {
        .modal {
          .modal-container {
            padding-top: 72px;

            @media (max-width: $screen-md) {
              padding-top: 0;
            }

            .modal-cover {
              display: none;
            }

            .modal-contents {
              .modal-header {
                opacity: 1;
              }

              .modal-body {
                overflow-y: auto;

                .modal-body-contents-container {
                  padding-bottom: 50%;
                  height: auto;
                  overflow-y: initial;
                }

                .modal-body-contents {
                  opacity: 1;
                }

                img {
                  filter: none;
                }
              }
            }
          }
        }
      }

      &.inactive {
        .modal {
          .modal-container {
            .modal-contents {
              .modal-body {
                .modal-body-contents-container {
                  /* width */
                  &::-webkit-scrollbar {
                    display: none !important;
                  }

                  /* Track */
                  &::-webkit-scrollbar-track {
                    display: none !important;
                  }

                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-bg {
      display: none;
    }
  }
}

// 관련 자료
$related-size: 360px;

.related-contents {
  z-index: 4000;

  .btn-related-contents {
    position: relative;
    display: block;
    border-radius: 30px;
    background-color: $color-white;
    background-image: url('../assets/images/ico-copy.svg');
    background-position: right 1rem center;
    background-repeat: no-repeat;
    z-index: 4020;
  }

  .related-contents-list {
    position: absolute;
    width: $related-size;
    height: $related-size;
    z-index: 4015;
    background-color: #fffbc0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    left: -$related-size;
    bottom: -$related-size;
    transition: all 500ms ease-in-out;

    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: 3.75rem;
      width: 100%;
      height: 1.5rem;
      background: linear-gradient(
        180deg,
        rgba(255, 251, 192, 0) 0%,
        #fffbc0 78.12%
      );
    }

    .related-contents-list-body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 3.75rem);
      overflow-y: scroll;
      padding-bottom: 3.75rem;
    }

    ul {
      li {
        padding: 0.5rem 0 0.5rem 2.5rem;
        background-image: url('../assets/images/ico-arrow-line-right-bold.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 2rem 1rem;

        @media (hover: hover) {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .related-contents-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4010;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    display: none;
  }

  &.open {
    left: 0;
    bottom: 0;

    .btn-related-contents {
      background-image: url('../assets/images/ico-close-black-sq.svg');
      background-size: 1rem;
    }

    .related-contents-list {
      left: 0;
      bottom: 0;
    }

    .related-contents-bg {
      // display: block;

      @media (max-width: $screen-sm) {
        display: none;
      }
    }
  }
}

.related-contents-histories {
  z-index: initial;

  .related-contents {
    z-index: initial;

    .btn-related-contents-container {
      padding-left: 2rem !important;
      padding-bottom: 3rem !important;
    }

    .btn-related-contents {
      z-index: 10;
    }

    .related-contents-list {
      z-index: 1;
    }

    .related-contents-bg {
      z-index: initial;
    }
  }
}

// Mini contents
.modal-mini-contents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - env(safe-area-inset-bottom));
  z-index: 3000;
  display: flex;
  align-items: center;

  &.right {
    justify-content: end;
  }

  .modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
  }

  .modal-contents {
    z-index: 3000;
    width: 100%;
    height: max-content;
    max-width: 400px;
    max-height: 861px;
    z-index: 3100;
    position: relative;
    background-color: $color-white;
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    background-color: #f1f1f1;
    overflow-y: auto;
    transition: all 300ms ease-in-out;

    @media (max-width: $screen-sm) {
      height: calc(100vh - env(safe-area-inset-bottom));
      max-height: 100%;
    }

    .modal-body {
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 2.5rem;
        position: sticky;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(241, 241, 241, 0) 0%,
          #f1f1f1 78.12%
        );
      }
    }
  }

  &.left {
    .modal-contents {
      transform: translateX(-100%);
    }

    &.open {
      .modal-contents {
        transform: translateX(0);
      }
    }
  }

  &.right {
    .modal-contents {
      transform: translateX(100%);
    }

    &.open {
      .modal-contents {
        transform: translateX(0);
      }
    }
  }
}

// Modal Custom
.modal-custom-2 {
  position: relative;

  /* &.modal-custom-2-cursor-move {
    @media (hover: hover) {
      &:hover {
        cursor: url('../assets/images/ico-cursor-history.svg'), auto;
      }
    }
  } */

  .modal-custom-2-list {
    position: relative;
    z-index: 20;

    ul {
      li {
        z-index: 21;
        width: max-content;

        .para {
          width: 220px;
        }

        .images {
          img {
            height: 140px;
            width: auto;
          }
        }
      }
    }
  }

  .modal-custom-2-map {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 763 / 480;
    background-image: url('../assets/images/img-map.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    content: ' ';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 66px;
    left: 0;
    background-color: $color-black;

    @media (max-width: $screen-md) {
      top: 4.25rem;
    }
  }
}

.btn-modal-custom {
  border: 1px solid transparent;
  &.current {
    background: #f1f1f1;
    border: 1px solid #000000;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
}
