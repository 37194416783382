.intro-tmp {
  background-image: url('../assets/images/img-intro-tmp.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro-bg {
  video {
    object-fit: cover;
    width: 100vw;
    height: calc(100vh - env(safe-area-inset-bottom));
    position: fixed;
    top: 0;
    left: 0;
  }

  &:after {
    content: ' ';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 46vh;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.4;
  }
}

.intro-contents {
  z-index: 10;
  padding-bottom: 85px;

  @media (max-width: $screen-md) {
    padding-bottom: 10rem;
  }
}

.intro-slider {
  width: 100%;
  max-width: 576px;
  margin-bottom: 3.625rem;

  @media (max-width: $screen-md) {
    max-width: 19.375rem !important;
  }

  .slick-list {
    color: $color-white;

    .slick-slide {
      transition: opacity 1500ms ease 0s, visibility 1500ms ease 0s !important;

      &.slick-active {
        transition: opacity 1500ms ease 1500ms, visibility 1500ms ease 1500ms !important;
      }
    }
  }

  .intro-para {
    @include font-styles(1.125rem, 140%, 500, $color-white);
    letter-spacing: -0.02em;
    width: 100%;
    max-width: 576px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

    @media (max-width: $screen-md) {
      width: 100% !important;
      @include font-styles(1rem, 140%, 500, $color-white);
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;

    li {
      width: 0.375rem;
      height: 0.375rem;
      opacity: 0.4;

      &.slick-active {
        opacity: 1;
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.line {
  transform-origin: 0% 50%;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.29, 0.135, 0.055, 1);
  animation-fill-mode: both;

  @for $i from 1 to 5 {
    &:nth-child(#{$i}) {
      animation-delay: 7s * $i;
    }
    &:nth-child(#{$i}) a::after {
      animation-delay: 2s * $i;
    }
  }

  &.line-init {
  }
}

.animation {
  .line {
    animation-name: line-appear;
    a::after {
      animation-name: underline-appear;
    }
  }
}

@keyframes line-appear {
  0% {
    transform: rotate(5deg) translate3D(0, 50%, 0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) translate3D(0, 0, 0);
    opacity: 1;
  }
}

@keyframes underline-appear {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
