.logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // JW Archive
  &.logo-jw-archive {
    background-image: url('../assets/images/logo-jw-archive.svg');

    &.black {
      background-size: contain;
      background-image: url('../assets/images/logo-jw-archive-black.svg');
    }
  }

  // JW Archive intro
  &.logo-jw-archive-intro {
    background-size: contain;
    background-image: url('../assets/images/logo-jw-archive-intro.svg');
  }

  // JW Symbol
  &.logo-jw-symbol {
    background-image: url('../assets/images/logo-jw-symbol.png');
  }

  // JW Voyage
  &.logo-jw-voyage {
    width: 124px;
    height: 88px;
    background-image: url('../assets/images/logo-voyage.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: $screen-md) {
      width: 5rem * 1.5;
      height: 2.625rem * 1.5;
      // margin-left: -2.5rem * 1.5;
    }
  }
}
